import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div className="conteudo_geral">
          <noscript
            aria-hidden="true"
            dangerouslySetInnerHTML={{
              __html:
                '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TQDRJ93" height="0" width="0" style="display:none;visibility:hidden" title="Google Tag Manager">Google Tag Manager</iframe>'
            }}
          />
          <script
            dangerouslySetInnerHTML={{
              __html:
                "\n// **** Scroll na pag 'como-funciona' : ****\njQuery(window).on('load', function() {\nvar numberSlides = 3;\t\t//**** Alterar este nr sempre que for necessario incluir novas div com conteudo nesta pagina especifica; corresponde ao nr total de divs\nvar distance = 500;\nvar timing = 750;\njQuery('#div_pageNr').html('1');\njQuery('#div_setacima').hide();\njQuery('#div_setacima').click(function() {\nvar next = parseFloat(jQuery('#div_pageNr').html()) - 1;\nvar myDist = '-'+((next * distance) - distance)+'px';\njQuery('.region-content').animate({\"margin-top\": myDist}, timing);\njQuery('#div_pageNr').html(next);\nif(next >= numberSlides ){\njQuery('#div_setabaixo').hide();\n} else {\njQuery('#div_setabaixo').fadeIn();\n}\nif(next <=1){\njQuery('#div_setacima').hide();\n} else {\njQuery('#div_setacima').fadeIn();\n}\n});\njQuery('#div_setabaixo').click(function() {\nvar next = parseFloat(jQuery('#div_pageNr').html()) + 1;\nvar myDist = '-'+((next * distance) - distance)+'px';\njQuery('.region-content').animate({\"margin-top\": myDist}, timing);\njQuery('#div_pageNr').html(next);\nif(next >=2){\t\t\t//**** Alterar este nr sempre que for necessario ajustar o nr de divs com conteudo a mostrar nesta pagina especifica\njQuery('#div_setabaixo').hide();\n} else {\njQuery('#div_setabaixo').fadeIn();\n}\nif(next <=1){\njQuery('#div_setacima').hide();\n} else {\njQuery('#div_setacima').fadeIn();\n}\n});\n});\n"
            }}
          />
          <div id="head_geral" className="container">
            <div className="container">
              <header id="head" role="banner">
                <div className="five columns alpha">
                  <div className="bisnaga" />
                </div>
                <div className="menu_linha_cima" />
                <div className="menu_linha_abaixo" />
                <div
                  className="eleven columns omega"
                  style={{
                    float: "right"
                  }}
                >
                  <div className="cabecalho_esq">
                    <div className="div_logo">
                      <a href="/" title="Início">
                        <img
                          className="img_logo"
                          src="/sites/default/files/color/responsive/logo.png"
                          alt="Início"
                        />
                      </a>{" "}
                    </div>
                  </div>
                  <nav id="navigation" role="navigation">
                    <div id="main-menu">
                      <ul className="menu">
                        <li className="first leaf">
                          <a href="/o-que-e-biafine">O que é biafine®</a>
                        </li>
                        <li className="leaf">
                          <a href="/quando-e-como-aplicar">
                            Quando e como <br/>aplicar
                          </a>
                        </li>
                        <li className="leaf">
                          <a href="/como-funciona">Como funciona</a>
                        </li>
                        <li className="leaf">
                          <a href="/balsamo-multi-reparador-apaziguante"><span className="blue-text">Cica</span> biafine® <br/><span className="align-leaf">Cosmético</span></a>
                        </li>
                        <li className="last leaf">
                          <a href="/contactos">Contactos</a>
                        </li>
                      </ul>{" "}
                    </div>
                  </nav>
                </div>
              </header>
            </div>
          </div>
          <div className="container" id="content-contain">
            <div className="menu_shadow" />
            <div id="content" className="sixteen columns">
              <div id="breadcrumbs">
                <h2 className="element-invisible">Está aqui</h2>
                <nav className="breadcrumb">
                  <a href="/">Início</a> » Política de Cookies
                </nav>
              </div>
              <section id="post-content" role="main">
                <h1 className="page-title">Política de Cookies</h1>
                <div
                  style={{
                    height: "450px",
                    overflow: "scroll",
                    "overflow-x": "hidden"
                  }}
                >
                  <div className="region region-content">
                    <div id="block-system-main" className="block block-system">
                      <div className="content">
                        <span
                          property="dc:title"
                          content="Aviso legal"
                          className="rdf-meta element-hidden"
                        />
                        <span
                          property="sioc:num_replies"
                          content="0"
                          datatype="xsd:integer"
                          className="rdf-meta element-hidden"
                        />
                        <div className="content node-page">
                          <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                            <div className="field-items">
                              <div
                                className="field-item even"
                                property="content:encoded"
                              >
                                <div>
                                  <p className="textoBlocoCinzaEscuro">
                                    A presente Política de Cookies presta
                                    informação relativa às nossas práticas de
                                    recolha de informação através de cookies e
                                    de outras tecnologias de rastreamento (tais
                                    como gifs, web beacons, etc.).
                                  </p>
                                  <p className="textoBlocoCinzaEscuro">
                                    As funcionalidades para as quais utilizamos
                                    estas tecnologias podem incluir o seguinte:
                                  </p>
                                  <ul>
                                    <li>
                                      Prestação de assistência aquando da
                                      navegação,
                                    </li>
                                    <li>
                                      Prestação de assistência aquando do seu
                                      registo nos nossos eventos, login e
                                      feedback,
                                    </li>
                                    <li>
                                      Análise do uso dos nossos produtos,
                                      serviços ou aplicações,
                                    </li>
                                    <li>
                                      Prestação de assistência relativamente aos
                                      nossos esforços promocionais e de
                                      marketing (incluindo, publicidade
                                      comportamental),
                                    </li>
                                    <li>
                                      Disponibilização de conteúdos de terceiros
                                      (tais como conteúdos de social media).
                                    </li>
                                  </ul>
                                  <br />
                                  <p className="textoBlocoCinzaEscuro">
                                    Abaixo encontra uma lista detalhada dos
                                    cookies que usamos, com a respetiva
                                    descrição. Podemos classificar os cookies
                                    com as seguintes categorias:
                                  </p>
                                  <ul>
                                    <li>Cookies estritamente necessários</li>
                                    <li>Cookies de performance</li>
                                    <li>Cookies funcionais</li>
                                    <li>Cookies de segmentação</li>
                                  </ul>
                                  <br />
                                  <p className="textoBlocoCinzaEscuro">
                                    Salvo quando permitido por lei, colocamos
                                    cookies após termos recebido o seu
                                    consentimento através do banner de cookies
                                    ou centro de gestão de preferências. Pode
                                    alterar, a qualquer momento, os settings dos
                                    cookies por categoria de cookies (com
                                    exceção dos cookies estritamente
                                    necessários, que são exigidos para o correto
                                    funcionamento do site), clicando no botão
                                    abaixo "Settings dos cookies":
                                  </p>
                                  <button id="ot-sdk-btn" className="ot-sdk-show-settings">
                                    Configuração de cookies
                                  </button>
                                  <br />
                                  <p>&nbsp;</p>
                                  <div id="optanon-cookie-policy" />
                                  <br />
                                  <h3>Links para outros sites</h3>
                                  <p className="textoBlocoCinzaEscuro">
                                    Este site contém links para ou referências a
                                    outros sites. Tenha em consideração que não
                                    controlamos os cookies/tecnologias de
                                    rastreio de outros sites, não se lhes
                                    aplicando a presente Política de Cookies.
                                  </p>
                                  <h3>Como nos contactar</h3>
                                  <p className="textoBlocoCinzaEscuro">
                                    Caso tenha alguma questão, comentário,
                                    dúvidas relativas a esta Política de Cookies
                                    ou a qualquer prática nos nossos sites,
                                    agradecemos que utilize a informação de
                                    contacto disponível na Política de
                                    Privacidade.
                                  </p>
                                  <h3>Alterações à Política de Cookies</h3>
                                  <p className="textoBlocoCinzaEscuro">
                                    Poderemos alterar esta Política de Cookies.
                                    Nessa situação, será disponibilizada no site
                                    a nova versão. Esta Política de Cookies foi
                                    atualizada em 17-09-2021.
                                  </p>
                                </div>
                                <p>&nbsp;</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <footer></footer>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="clear" />
          </div>
          <div id="copyright_geral" className="container">
            <div id="copyright" className="container">
              <div className="background_img_interior" />
              <div className="rodape_esq">
                <div className="region region-footer">
                  <div id="block-block-1" className="block block-block">
                    <div className="content">
                      <p>
                        www.biafine.pt © Johnson &amp; Johnson Limitada.
                        PT/BIA/16-1068
                      </p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rodapeDir rodape_dir">
                <div className="region region-footer-menu">
                  <div
                    id="block-menu-menu-menu-rodape"
                    className="block block-menu"
                  >
                    <div className="content">
                      <ul className="menu">
                        <li className="first leaf">
                          <a href="/politica-de-privacidade" title>
                            POLÍTICA DE PRIVACIDADE
                          </a>
                        </li>
                        <li className="leaf active-trail">
                          <a
                            href="/cookie-policy"
                            className="active-trail active"
                            title
                          >
                            POLÍTICA DE COOKIES
                          </a>
                        </li>
                        <li className="leaf">
                          <a href="/aviso-legal" title>
                            AVISO LEGAL
                          </a>
                        </li>
                        <li className="leaf">
                          <a href="/mapa-do-site" title>
                            MAPA DO SITE
                          </a>
                        </li>
                        <li className="leaf">
                          <a href="/contactos" title>
                            CONTACTOS
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rodape_centro">
                <div className="region region-footer-center">
                  <div id="block-block-4" className="block block-block">
                    <div className="content">
                      <div>
                        Este site é publicado pela Johnson &amp; Johnson
                        Limitada única responsável pelo seu
                        conteúdo.&nbsp;Destina-se a utilizadores em Portugal.
                      </div>
                      <div>
                        V03 BIAFINE® 6,7 mg/g, emulsão cutânea. Trolamina.
                        Medicamento não sujeito a receita médica. Indicado em
                        queimaduras do 1º grau, eritema solar e feridas cutâneas
                        superficiais não infetadas. BIAFINE® não confere
                        proteção solar e deve ser afastado dos olhos. Não deve
                        ser utilizado como um produto cosmético em pele
                        saudável. Contém parabenos, propilenoglicol e sorbato de
                        potássio que podem originar reações cutâneas ou
                        alérgicas. Contraindicado em doentes com
                        hipersensibilidade à substância ativa ou a qualquer um
                        dos excipientes e em feridas com hemorragia e infetadas.
                        Leia cuidadosamente as informações constantes da
                        embalagem e do folheto informativo. Em caso de dúvida ou
                        de persistência dos sintomas consulte o seu médico ou
                        farmacêutico. Johnson &amp; Johnson Lda. Lagoas Park,
                        Edifício 9, 2740-262 Porto Salvo. NUIPC 500 153 370.
                        <br />
                        &nbsp;
                      </div>
                      <div>&nbsp;</div>
                      <div className="last leaf">
                        <a id="ot-sdk-btn" className="ot-sdk-show-settings">
                          Configuração de cookies
                          </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="clear" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Page;
